<template>
  <b-card-code class="col-12" :title="$t('stands_title')">
    <b-row class="col-12">
      <b-col class="col-9">
        <vue-good-table
          :columns="columns"
          :rows="list"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          styleClass="vgt-table table-hover"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'name'" class="row-logo">
              <div>
                <b-img :src="props.row.logo_url" />
              </div>
              <a
                @click="goToStatsSingleStand(props.row.id)"
                href="javascript:void(0)"
                style="font-weight: bold;"
                v-if="props.row.name != ''"
                >{{ props.row.name }}</a
              >
            </span>
            <span v-if="props.column.field == 'Position'">
              <span> {{ props.row.external_ref }}</span>
            </span>
            <span v-if="props.column.field == 'visits'">
              <span> {{ props.row.visits }} </span>
            </span>
            <span v-if="props.column.field == 'click'">
              <span> {{ props.row.pinpoints.sum_click }} </span>
            </span>
            <span v-if="props.column.field == 'contacts'">
              <span> {{ props.row.contacts }} </span>
            </span>
            <span v-if="props.column.field === 'action'">
              <feather-icon
                @click="goToStatsSingleStand(props.row.id)"
                icon="EyeIcon"
                class="mr-50"
              />
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  {{ $t("showing") }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">
                  {{ $t("showing_pt2", { total_entries }) }}
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
      <b-row class="d-flex col-3">
        <b-col class="col-12">
          <statistic-card-vertical
            icon="UserIcon"
            color="info"
            :statistic="visits"
            :statistic-title="$t('stats.visits_stands')"
          />
        </b-col>
        <b-col class="col-12">
          <statistic-card-vertical
            icon="UserIcon"
            color="success"
            :statistic="interactions"
            :statistic-title="$t('stats.interactions_stands')"
          />
        </b-col>
        <b-col class="col-12">
          <statistic-card-vertical
            icon="MailIcon"
            color="warning"
            :statistic="contacts"
            :statistic-title="$t('stats.contacts_stands')"
          />
        </b-col>
      </b-row>
    </b-row>
  </b-card-code>
</template>

<script>
import {
  BAvatar,
  BPagination,
  BImg,
  BFormSelect,
  BCol,
  BRow,
} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import Ripple from "vue-ripple-directive";
import BCardCode from "@/@core/components/b-card-code/BCardCode.vue";

export default {
  props: {
    stands_list: {
      type: Array,
    },
    sum_visits: {
      type: Number,
    },
    sum_interactions: {
      type: Number,
    },
    sum_contacts: {
      type: Number,
    },
  },
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BCardCode,
    BImg,
    BFormSelect,
    StatisticCardVertical,
    BCol,
    BRow,
  },
  data() {
    return {
      pageLength: 10,
      columns: [
        {
          field: "name",
          label: this.$t("field_label_name"),
          sortFn: this.sortNameStand,
        },
        {
          field: "Position",
          label: this.$t("Position"),
          sortFn: this.sortPosition,
        },
        {
          field: "visits",
          label: this.$t("stats.visits"),
          sortFn: this.sortVisitStand,
        },
        {
          field: "click",
          label: "Click",
          sortFn: this.sortClickStand,
        },
        {
          field: "contacts",
          label: this.$t("stats.contacts"),
          sortFn: this.sortContactStand,
        },
        {
          field: "action",
          sortable: false,
        },
      ],
      list: this.stands_list,
      total_entries: null,
      visits: this.sum_visits,
      interactions: this.sum_interactions,
      contacts: this.sum_contacts,
    };
  },
  directives: {
    Ripple,
  },
  methods: {
    sortNameStand(x, y, col, rowX, rowY) {
      x = rowX.name;
      y = rowY.name;
      return x < y ? -1 : x > y ? 1 : 0;
    },
    sortPosition(x, y, col, rowX, rowY) {
      x = rowX.external_ref;
      y = rowY.external_ref;
      return x < y ? -1 : x > y ? 1 : 0;
    },
    sortVisitStand(x, y, col, rowX, rowY) {
      x = rowX.visits;
      y = rowY.visits;
      return x < y ? -1 : x > y ? 1 : 0;
    },
    sortClickStand(x, y, col, rowX, rowY) {
      x = rowX.pinpoints.sum_click;
      y = rowY.pinpoints.sum_click;
      return x < y ? -1 : x > y ? 1 : 0;
    },
    sortContactStand(x, y, col, rowX, rowY) {
      x = rowX.contacts;
      y = rowY.contacts;
      return x < y ? -1 : x > y ? 1 : 0;
    },
    goToStatsSingleStand(id) {
      this.$router.push({
        path: `/statistics/stand/${id}`,
        params: { id: id },
      });
    },
  },
  watch: {
    stands_list: {
      handler() {
        this.list = this.stands_list;
        this.total_entries = this.stands_list.length;
      },
      deep: true,
    },
    sum_visits() {
      this.visits = this.sum_visits;
    },
    sum_contacts() {
      this.contacts = this.sum_contacts;
    },
    sum_interactions() {
      this.interactions = this.sum_interactions;
    },
  },
};
</script>

<style></style>
